<template>
  <div id="consume">
    <div class="mian" v-if="list.length">
      <van-list v-model="loading" :finished="finished" @load="onReachBottom">
        <div class="main_item" v-for="item in list" :key="item.id">
          <div class="item_title">
            <div>{{item.create_time}}</div>
            <div>{{item.source_text}}</div>
          </div>
          <!-- 备注： -->
          <div class="item_remark van-ellipsis">{{language.l_163}}{{item.remark}}</div>
          <div class="item_amount">{{item.type_text}}：<span>${{item.amount}}</span></div>
        </div>
      </van-list>
    </div>
    <div class="mian" v-else>
      <!-- 暂无消费记录 -->
      <van-empty :description="language.l_164" />
    </div>
  </div>
</template>

<script>
import {getConsume} from "@api"

export default {
  data() {
    return {
      list: [],
      params: {
        page: 1,
        page_size: 10
      },
      loading: false,
      finished: true,
      count: 0,
      language: this.$store.getters.language
    }
  },
  created() {
    this.getData(this.params)
  },
  methods: {
    getData(params, callback) {
      getConsume(params).then(res => {
        if(callback) return callback(res.data);
        const {data: list, count} = res.data;
        this.refreshing = false;
        this.finished = true;
        this.count = count
        this.list = list

        if(count > params.page_size) { // 若数据大于1页条数，则允许上拉加载
          this.finished = false;
          this.params.page++;
        }
      })
    },
    onReachBottom() {
      const {params, count, list} = this;
      const maxPage = Math.ceil(count / params.page_size); // 最大页码
      this.getData(params, res => {
        this.loading = false;
        this.list = [...list, ...res.data];
        this.params.page++;
        if(this.params.page > maxPage) {
          this.params.page--;
          this.finished = true;
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
#consume {
  .safe_padding_bottom();
  .mian {
    width: 100%;
    .main_item {
      height: 100px;
      margin: 10px;
      border-radius: 10px;
      background: #fff;
      font-size: 14px;
      .item_title {
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        div:nth-child(2) {
          color: @c_deep;
        }
      }
      .item_remark {
        padding: 0 15px;
      }
      .item_amount {
        padding: 10px 15px;
        float: right;
        span {
          color: @c_deep;
        }
      }
    }
  }
}
</style>